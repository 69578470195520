import * as React from "react";
import CtaButton from "../CtaButton/CtaButton";
import {
  cardContainer,
  cardContentContainer,
  cardShadow,
} from "./ResourceCard.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

/**
 * Returns the card with content within the resources section
 * Content is being populated from GraphQL query running on the Indium page
 * Indium > Resources > Resource card
 *
 * extension is needed only if we do not restrict image type usage coming from strapi.
 * Otherwise Gatsby will not handle the image correctly as when gatsby creates responsive images
 * it uses the <picture> tag with srcset which isn't applicable to svgs
 *
 * https://stackoverflow.com/questions/53628498/gatsby-image-svg-not-found
 * https://github.com/gatsbyjs/gatsby/issues/10297
 *
 * The actual warning that populates when you try to feed an svg into <GatsbyImage /> recommends using the publicURL via img
 */

export default function ResourceCard({
  resourceImage,
  resourceText,
  resourceButton,
  resourceLink,
}) {
  return (
    <div className={cardContainer}>
      <div className={cardShadow}>
        <div className={cardContentContainer} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "1.5rem" }}>
            {/* We need to check to make sure that the image is not an SVG before using GatsbyImage since Gatsby does not process SVGs */}
            {resourceImage?.extension === "svg" ? (
              <img src={resourceImage?.publicURL} alt="" />
            ) : (
              <GatsbyImage
                image={resourceImage?.childImageSharp.gatsbyImageData}
                alt=""
              />
            )}
          </div>
          <h5>{resourceText}</h5>
          <CtaButton
            name={resourceButton}
            link={resourceLink}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
}
