import * as React from "react";
import ResourceCard from "../ResourceCard/ResourceCard";
import { resourcesContainer } from "./Resources.module.scss";

/**
 * This copmonent returns the content for the Resources section (currently only on the Indium page)
 *
 * The resources section contains a .map of the <ResourceCard /> which is currently sourced from the
 * Strapi resource collection, which is coming from the Indium page static query. All data can be sourced
 * in Strapi and passed to the ResourceCard component.
 *
 */

export default function Resources({ resources }) {
  return (
    <div className={resourcesContainer}>
      <h2>Resources</h2>
      {resources.map((resource) => {
        const { id, image, header, link, button_text } = resource.resource;
        return (
          <ResourceCard
            key={id}
            resourceImage={image?.localFile}
            resourceText={header}
            resourceButton={button_text}
            resourceLink={link}
          />
        );
      })}
    </div>
  );
}
