import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../Layouts/Layout/Layout";
import Seo from "../components/Seo/Seo";
import Hero from "../components/Hero/Hero";
import AgencyHighlights from "../components/AgencyHighlights/AgencyHighlights";
import RocketReferralsCta from "../components/RocketReferralsCta/RocketReferralsCta";
import Resources from "../components/Resources/Resources";
import ScoreComparison from "../components/ScoreComparison/ScoreComparison";

import {
  indiumBannerImage,
  indiumCtaBanner,
} from "../styles/landing.module.scss";
import {
  sky,
  cloud,
  mountainLeft,
  mountainRight,
} from "../styles/design.module.scss";

/**
 * Indium landing page based on homepage with slight adjustments for additional sections and images
 *
 */

function Indium() {
  const getIndiumContent = useStaticQuery(graphql`
    query fetchIndiumPageContent {
      strapiPages(name: { eq: "indium" }) {
        name
        hero {
          Title
          Description
        }
      }

      allStrapiResources(
        filter: { pages: { elemMatch: { name: { eq: "indium" } } } }
      ) {
        nodes {
          resource {
            id
            header
            button_text
            link
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 160)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
    }
  `);

  const { hero, name } = getIndiumContent.strapiPages;
  const indiumResources = getIndiumContent.allStrapiResources.nodes;

  return (
    <Layout homepageLink={`/${name}`}>
      <Seo title="Home" />
      <div
        style={{
          position: "relative",
          marginTop: "-4.5rem",
          paddingTop: "4.5rem",
        }}
      >
        <div
          className={indiumBannerImage}
          style={{
            bottom: "200px",
          }}
        ></div>
        <section className="wrapper">
          <Hero
            heroTextContent={hero}
            enterprise={true}
            enterpriseName="Indium"
          />
        </section>
      </div>
      <section className="wrapper">
        <AgencyHighlights
          enterprise={true}
          enterpriseName="Indium"
          cardType="agency"
        />
      </section>
      <div style={{ position: "relative" }}>
        <div className={indiumCtaBanner}></div>
        <section className="wrapper">
          <RocketReferralsCta
            h2="See why you should partner with Indium"
            buttonText="Learn more about Indium"
            link="https://www.goindium.com/"
          />
        </section>
      </div>
      <section className="wrapper">
        <Resources resources={indiumResources} />
      </section>
      <section className="wrapper">
        <ScoreComparison />
      </section>
      <div style={{ position: "relative" }}>
        <div className={sky}>
          <div
            className="wrapper"
            style={{ position: "relative", zIndex: "1500", height: "100%" }}
          >
            <StaticImage
              className={mountainLeft}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_left.svg"
              alt=""
            />
            <StaticImage
              className={mountainRight}
              objectFit="contain"
              placeholder="none"
              quality="100"
              src="../images/mountain_right.svg"
              alt=""
            />
          </div>

          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
          <span className={cloud}></span>
        </div>
        <RocketReferralsCta
          h2="We can make NPS work for you."
          buttonText="Check out Rocket Referrals"
          link="https://www.rocketreferrals.com/features"
        />
      </div>
    </Layout>
  );
}

export default Indium;
